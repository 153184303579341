import { ComputedRef, Ref } from '@vue/composition-api'
import { DataOptions } from 'vuetify'
import { TranslateResult } from 'vue-i18n'
import { DebouncedFunc } from 'lodash-es'

import { PaginationParams } from '@/composables/types/usePagination'

enum FIELD_TYPE {
  Searchfield = 'SEARCHFIELD',
  Autocomplete = 'AUTOCOMPLETE',
  Dropdown = 'DROPDOWN',
  Chipgroup = 'CHIPGROUP',
  ActiveSwitch = 'ACTIVE_SWITCH',
  Range = 'RANGE',
}

enum FilterDataTypes {
  String = 'string',
  ArrayNumber = 'array-number',
  ArrayString = 'array-string',
  Date = 'date',
  Float = 'float',
  PercentageNumber = 'percentageNumber',
  BooleanDate = 'boolean-date',
  Boolean = 'boolean',
  Integer = 'integer',
}

interface FilterField {
  key: string
  value: unknown
  label: TranslateResult
  items: ComputedRef<unknown[]> | []
  isLoading?: ComputedRef<boolean>
  dataTyp: FilterDataTypes
}

interface UseFilterReturnType {
  debouncedCb: ComputedRef<DebouncedFunc<() => Promise<void>>>
  vuetifyTableOptions: Ref<DataOptions>
  paginationParams: ComputedRef<PaginationParams>
  filterFieldsObject: ComputedRef<OptionalFiltersParam>
  isInit: Ref<boolean>
}

type OptionalFiltersParam = Record<string, unknown>

type UseFilter = (
  FILTER_FIELDS: FilterField[],
  cb: (filters?: OptionalFiltersParam) => Promise<void>,
  withoutFilterPersistence: boolean
) => UseFilterReturnType

export { FIELD_TYPE, FilterField, FilterDataTypes, UseFilter, UseFilterReturnType, OptionalFiltersParam }
