import { computed, ComputedRef, ref } from '@vue/composition-api'
import { DataOptions } from 'vuetify'

import { PaginationParams, UsePagination } from '@/composables/types/usePagination'

const usePagination = (
  initialPaginationParams: DataOptions = {
    page: 1,
    itemsPerPage: 10,
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: true,
  }
): UsePagination => {
  const vuetifyTableOptions = ref<DataOptions>(initialPaginationParams)

  const paginationParams: ComputedRef<PaginationParams> = computed(() => ({
    page: vuetifyTableOptions.value.page - 1,
    size: vuetifyTableOptions.value.itemsPerPage >= 0 ? vuetifyTableOptions.value.itemsPerPage : 9001,
    sort: vuetifyTableOptions.value.sortBy?.[0]
      ? `${vuetifyTableOptions.value.sortBy[0]},${
          vuetifyTableOptions.value.sortDesc ? (vuetifyTableOptions.value.sortDesc[0] ? 'desc' : 'asc') : ''
        }`
      : undefined,
  }))

  return {
    vuetifyTableOptions,
    paginationParams,
  }
}

export default usePagination
