import { ref } from '@vue/composition-api'

import { BasicTaskOutput } from '@/api/types/task'

import { UseTaskDialogReturnType, Entity, EntityRef } from '@/composables/types/useTaskDialog'
import { OptionalFiltersParam } from '@/composables/types/useFilter'

function useTaskDialog<T>(
  parentEntity: EntityRef<T>,
  reloadCallback: (filterFieldsObject?: OptionalFiltersParam) => Promise<void>
): UseTaskDialogReturnType<T> {
  const isOpen = ref(false)

  const entityId = ref<number | null>(null)

  const tasks = ref<BasicTaskOutput[]>([])

  function assignTasks(newTasks: BasicTaskOutput[]) {
    tasks.value = [...newTasks]
  }

  function onClickOpenDialog(entity: Entity<T>) {
    isOpen.value = true

    entityId.value = entity.id

    assignTasks(entity.tasks)
  }

  async function onReload(): Promise<void> {
    let newTasks: BasicTaskOutput[] = []

    await reloadCallback()

    newTasks = parentEntity.value.find((entity) => entity.id === entityId.value)?.tasks ?? []

    assignTasks(newTasks)
  }

  return {
    isOpen,
    tasks,
    onClickOpenDialog,
    onReload,
    entityId,
  }
}

export { useTaskDialog }
