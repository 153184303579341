import { Ref } from '@vue/composition-api'
import { DataOptions } from 'vuetify'
import { SortParams } from './types/manipulateTableSort'

export function addSort(tableOptions: Ref<DataOptions>, sortParams: SortParams): void {
  tableOptions.value.sortBy.push(sortParams.by)
  tableOptions.value.sortDesc.push(sortParams.desc)
}

export function setSort(tableOptions: Ref<DataOptions>, sortParams: SortParams): void {
  tableOptions.value.sortBy = [sortParams.by]
  tableOptions.value.sortDesc = [sortParams.desc]
}

export function clearSort(tableOptions: Ref<DataOptions>): void {
  tableOptions.value.sortBy = []
  tableOptions.value.sortDesc = []
}
