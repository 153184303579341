var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-menu', {
    attrs: {
      "content-class": "rounded-lg elevation-2",
      "offset-y": "",
      "close-on-content-click": false,
      "max-width": "250px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var attrs = _ref.attrs,
            on = _ref.on;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "rounded-lg white",
          attrs: {
            "text": "",
            "color": "primary",
            "elevation": "0"
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiFileExport) + " ")]), _vm._v(" " + _vm._s(_vm.$t('misc.export.title')) + " ")], 1)];
      }
    }]),
    model: {
      value: _vm.state.isMenuOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isMenuOpen", $$v);
      },
      expression: "state.isMenuOpen"
    }
  }, [_c('v-card', {
    on: {
      "keydown": [function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) { return null; }
        _vm.state.isMenuOpen = false;
      }, function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        _vm.state.hasCancelButtonFocus ? null : _vm.listeners.onExport();
      }]
    }
  }, [_c('v-card-text', {
    staticClass: "pb-0"
  }, [_c('v-form', {
    ref: "formElement",
    model: {
      value: _vm.state.isFormValid,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isFormValid", $$v);
      },
      expression: "state.isFormValid"
    }
  }, [_c('CommonMonthDateInput', {
    staticClass: "mb-4",
    attrs: {
      "hide-details": "",
      "outlined": "",
      "dense": "",
      "label": _vm.$t('misc.export.filters.start')
    },
    model: {
      value: _vm.state.startMonth,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "startMonth", $$v);
      },
      expression: "state.startMonth"
    }
  }), _c('CommonMonthDateInput', {
    attrs: {
      "hide-details": "",
      "outlined": "",
      "dense": "",
      "label": _vm.$t('misc.export.filters.end')
    },
    model: {
      value: _vm.state.endMonth,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "endMonth", $$v);
      },
      expression: "state.endMonth"
    }
  })], 1), !_vm.state.isTimeSpanValid && _vm.state.isFormValid ? _c('span', {
    staticClass: "text-subtitle-3 red--text mt-2 d-block"
  }, [_vm._v(" " + _vm._s(_vm.$t('misc.export.invalidTimeSpan')) + " ")]) : _vm._e(), _c('p', {
    staticClass: "mt-1 mb-0 text-subtitle-3"
  }, [_vm._v(" " + _vm._s(_vm.$t('misc.export.durationHint')) + " ")])], 1), _c('v-card-actions', [_c('v-btn', _vm._b({
    staticClass: "rounded-lg",
    attrs: {
      "elevation": "0",
      "color": "primary",
      "disabled": !_vm.state.isFormValid || !_vm.state.isTimeSpanValid,
      "small": ""
    },
    on: {
      "focus": function focus($event) {
        _vm.state.hasCancelButtonFocus = false;
      },
      "click": function click($event) {
        return _vm.listeners.onExport();
      }
    }
  }, 'v-btn', _vm.$attrs, false), [_vm._v(" " + _vm._s(_vm.$t('misc.export.actions.export')) + " ")]), _c('v-btn', {
    staticClass: "rounded-lg",
    attrs: {
      "elevation": "0",
      "text": "",
      "small": ""
    },
    on: {
      "click": function click($event) {
        _vm.state.hasCancelButtonFocus ? _vm.state.isMenuOpen = false : null;
      },
      "focus": function focus($event) {
        _vm.state.hasCancelButtonFocus = true;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('misc.export.actions.close')) + " ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }