import { useAxios } from '@/composables/useAxios'
import { useAxiosPaginated } from '@/composables/useAxiosPaginated'

import {
  UseGetOrgUnits,
  UseCreateOrgUnit,
  UseUpdateOrgUnit,
  UseDeleteOrgUnit,
  OrgUnit,
  NewOrgUnit,
  OrgUnitId,
  UseGetOrgUnitsBasic,
} from '@/api/types/orgUnit'
import { BasicEntity } from '@/api/types/misc'
import { PromiseType } from '@/utils/types/PromiseType'

const VERSION = 'v1'
const RESOURCE = 'organizational-units'

function useGetOrgUnits<T>(): UseGetOrgUnits<T> {
  const axios = useAxiosPaginated<T>({ method: 'GET', url: `/${VERSION}/${RESOURCE}`, params: { size: 9999 } })
  return axios
}

function useGetOrgUnitsBasic(): UseGetOrgUnitsBasic {
  const axios = useAxios<BasicEntity[]>({ method: 'GET', url: `/${VERSION}/${RESOURCE}/basic` })
  return axios
}

function useCreateOrgUnit(): UseCreateOrgUnit {
  const axios = useAxios<PromiseType<ReturnType<UseCreateOrgUnit['createOrgUnit']>>>({
    method: 'POST',
    url: `/${VERSION}/${RESOURCE}`,
  })

  function createOrgUnit(data: NewOrgUnit): Promise<OrgUnit> {
    return axios.exec({ data })
  }

  return {
    ...axios,
    createOrgUnit,
  }
}

function useUpdateOrgUnit(): UseUpdateOrgUnit {
  const axios = useAxios<PromiseType<ReturnType<UseUpdateOrgUnit['updateOrgUnit']>>>({ method: 'PUT' })

  function updateOrgUnit(id: OrgUnitId, data: OrgUnit): Promise<OrgUnit> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}`, data })
  }

  return {
    ...axios,
    updateOrgUnit,
  }
}

function useDeleteOrgUnit(): UseDeleteOrgUnit {
  const axios = useAxios<PromiseType<ReturnType<UseDeleteOrgUnit['deleteOrgUnit']>>>({ method: 'DELETE' })

  function deleteOrgUnit(id: OrgUnitId): Promise<void> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}` })
  }

  return {
    ...axios,
    deleteOrgUnit,
  }
}

export { useGetOrgUnits, useGetOrgUnitsBasic, useCreateOrgUnit, useUpdateOrgUnit, useDeleteOrgUnit }
