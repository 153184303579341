var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('CommonTableView', {
    attrs: {
      "headers": _vm.constants.TABLE_HEADERS,
      "items": _vm.state.persons,
      "loading": _vm.state.isLoadingPersons,
      "search": _vm.state.tableSearchTerm,
      "with-notes-and-tasks": "",
      "with-info": false,
      "options": _vm.state.vuetifyTableOptions,
      "server-items-length": _vm.state.totalPersons
    },
    on: {
      "update:options": [function ($event) {
        return _vm.$set(_vm.state, "vuetifyTableOptions", $event);
      }, function ($event) {
        return _vm.functions.debouncedCb();
      }],
      "dblclick:row": function dblclickRow() {
        var arguments$1 = arguments;

        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments$1[_key];
        }

        return _vm.listeners.onDblClickRow(args);
      },
      "click:delete": function clickDelete($event) {
        return _vm.listeners.onClickDelete($event);
      },
      "click:tasks": function clickTasks($event) {
        return _vm.listeners.onOpenTasksDialog($event);
      },
      "click:notes": function clickNotes($event) {
        return _vm.listeners.onOpenNotesDialog($event);
      }
    },
    scopedSlots: _vm._u([{
      key: "table-header",
      fn: function fn() {
        return [_c('v-row', {
          staticClass: "ma-0 pb-3",
          attrs: {
            "align": "center"
          }
        }, [_vm.functions.hasSufficientRights(_vm.constants.Rights.BASIC_READ) ? _c('v-btn', {
          staticClass: "mr-5 rounded-lg white",
          attrs: {
            "text": "",
            "elevation": "0",
            "color": "primary"
          },
          on: {
            "click": _vm.listeners.onToggleFilterDropdown
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiFilter) + " ")]), _vm._v(" " + _vm._s(_vm.$t('misc.filter')) + " "), _c('v-icon', {
          attrs: {
            "right": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.state.isFilterDropdownOpen ? _vm.icons.mdiChevronUp : _vm.icons.mdiChevronDown) + " ")])], 1) : _vm._e(), _c('CommonExportMenu', {
          attrs: {
            "loading": _vm.state.isLoadingExport
          },
          on: {
            "export": function _export($event) {
              return _vm.listeners.onExportPersons($event);
            }
          }
        }), _vm.functions.hasSufficientRights(_vm.constants.Rights.PERSON_CREATE) && _vm.functions.hasSufficientRights(_vm.constants.Rights.BASIC_READ) ? _c('v-btn', {
          staticClass: "ml-auto rounded-lg",
          attrs: {
            "color": "primary",
            "elevation": "0"
          },
          on: {
            "click": function click($event) {
              _vm.state.isAddPersonModalOpen = true;
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiPlus) + " ")]), _vm._v(" " + _vm._s(_vm.$t('persons.actions.addPerson')) + " ")], 1) : _vm._e()], 1), _c('v-expand-transition', {
          attrs: {
            "mode": "in-out"
          }
        }, [_vm.state.isFilterDropdownOpen ? _c('PersonFilterBar', {
          staticClass: "d-flex align-center",
          attrs: {
            "fields": _vm.constants.FILTER_FIELDS
          }
        }) : _vm._e()], 1)];
      },
      proxy: true
    }, {
      key: "personScope",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('span', [_vm._v(" " + _vm._s(_vm.functions.convertDotIntoCommaInput(Number(item.activeAssignmentScope.toFixed(1)), 2)) + " / " + _vm._s(_vm.functions.convertDotIntoCommaInput(Number(item.activeContractScope.toFixed(1)), 2)) + " ")])];
      }
    }, {
      key: "specialRoles",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return _vm._l(item.specialRoles, function (specialRole) {
          return _c('span', {
            key: specialRole.id
          }, [_c('v-chip', {
            staticClass: "my-1 mr-1",
            attrs: {
              "small": ""
            }
          }, [_vm._v(" " + _vm._s(specialRole.name) + " ")])], 1);
        });
      }
    }, {
      key: "item.actions.delete",
      fn: function fn() {
        return [!_vm.functions.hasSufficientRights(_vm.constants.Rights.PERSON_DELETE) ? _c('div') : _vm._e()];
      },
      proxy: true
    }], null, true)
  }, [_vm.state.isAddPersonModalOpen ? _c('AddEditPersonDialog', {
    on: {
      "added-edited": function addedEdited($event) {
        return _vm.functions.debouncedCb();
      },
      "close": function close($event) {
        return _vm.listeners.onCloseAddDeleteDialog();
      }
    },
    model: {
      value: _vm.state.isAddPersonModalOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isAddPersonModalOpen", $$v);
      },
      expression: "state.isAddPersonModalOpen"
    }
  }) : _vm._e(), _vm.state.isTasksDialogOpen ? _c('CommonTasksDialog', {
    attrs: {
      "tasks": _vm.state.personTasks,
      "entity-name": "person",
      "entity-id": _vm.state.tasksEntityId
    },
    on: {
      "reload-tasks": function reloadTasks($event) {
        return _vm.listeners.onReloadTasks();
      },
      "close": function close($event) {
        _vm.state.isTasksDialogOpen = false;
      }
    },
    model: {
      value: _vm.state.isTasksDialogOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isTasksDialogOpen", $$v);
      },
      expression: "state.isTasksDialogOpen"
    }
  }) : _vm._e(), _vm.state.isNotesDialogOpen ? _c('CommonNotesDialog', {
    attrs: {
      "notes": _vm.state.personNotes,
      "entity-name": "person",
      "entity-id": _vm.state.notesEntityId
    },
    on: {
      "reload-notes": function reloadNotes($event) {
        return _vm.listeners.onReloadNotes();
      },
      "close": function close($event) {
        _vm.state.isNotesDialogOpen = false;
      }
    },
    model: {
      value: _vm.state.isNotesDialogOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isNotesDialogOpen", $$v);
      },
      expression: "state.isNotesDialogOpen"
    }
  }) : _vm._e(), _vm.state.isDeletePersonDialogOpen ? _c('CommonDeleteDialog', {
    on: {
      "delete": function _delete($event) {
        return _vm.listeners.onDeletePerson();
      },
      "cancel": function cancel($event) {
        return _vm.listeners.onCloseAddDeleteDialog();
      }
    },
    model: {
      value: _vm.state.isDeletePersonDialogOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isDeletePersonDialogOpen", $$v);
      },
      expression: "state.isDeletePersonDialogOpen"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('persons.deletePerson.text', {
    name: "".concat(_vm.state.activePerson.firstName, " ").concat(_vm.state.activePerson.lastName)
  })) + " ")]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }