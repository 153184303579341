
import { defineComponent, reactive } from '@vue/composition-api'

import { isValidDotNotationMonthDate } from '@/utils/validation'

export default defineComponent({
  name: 'CommonMonthDateInput',
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      required: false,
      default: null,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  setup: () => {
    return reactive({
      formRules: {
        isValidDotNotationMonthDate,
      },
    })
  },
})
