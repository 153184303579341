import { computed, ref, watch } from '@vue/composition-api'
import { cloneDeep, debounce } from 'lodash-es'

import router from '@/router'

import usePagination from '@/composables/usePagination'

import { dateDotNotationToDash } from '@/utils/convertDate'
import { isOfTypeString } from '@/utils/isOfTypeString'

import { FilterDataTypes, FilterField, OptionalFiltersParam, UseFilterReturnType } from '@/composables/types/useFilter'

function useFilter(
  FILTER_FIELDS: FilterField[],
  cb: (filterFieldsObject?: OptionalFiltersParam) => Promise<void>,
  withoutFilterPersistence = false
): UseFilterReturnType {
  const isInit = ref(true)

  const filterFieldsObject = computed(() => createFilterFieldsObject(FILTER_FIELDS))

  const debouncedCb = computed(() => debounce(() => cb(filterFieldsObject.value), isInit.value ? 0 : 500))

  const { vuetifyTableOptions, paginationParams } = usePagination({
    page: Number(router.currentRoute.query['page']) ? Number(router.currentRoute.query['page']) + 1 : 1,
    itemsPerPage: router.currentRoute.query['size'] ? Number(router.currentRoute.query['size']) : 10,
    sortBy: router.currentRoute.query['sort'] ? [(router.currentRoute.query['sort'] as string).split(',')[0]] : [],
    sortDesc:
      router.currentRoute.query['sort'] && (router.currentRoute.query['sort'] as string).split(',')[1] === 'desc'
        ? [true]
        : [false],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: true,
  })

  transformUrlParamsToFilterFields(FILTER_FIELDS)

  watch(
    [() => FILTER_FIELDS, () => vuetifyTableOptions.value],
    () => {
      if (withoutFilterPersistence) return
      const filters = {}

      Object.entries({ ...filterFieldsObject.value, ...paginationParams.value }).map(([key, value]) => {
        if (value !== undefined && value !== null && value !== '') {
          filters[key] = value
        }
      })

      router
        .push({
          query: { ...filters },
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .catch((e) => ({}))
    },
    { deep: true }
  )

  return {
    debouncedCb,
    vuetifyTableOptions,
    paginationParams,
    filterFieldsObject,
    isInit,
  }
}

function transformUrlParamsToFilterFields(FILTER_FIELDS: FilterField[]): void {
  const currentRouteQuery = router.currentRoute.query

  if (Object.keys(currentRouteQuery).length) {
    Object.keys(currentRouteQuery).forEach((queryKey: string) => {
      const filterFieldIndex = FILTER_FIELDS.findIndex((filterField: FilterField) => filterField.key === queryKey)

      if (filterFieldIndex === -1) return

      switch (FILTER_FIELDS[filterFieldIndex].dataTyp) {
        case FilterDataTypes.ArrayNumber:
          FILTER_FIELDS[filterFieldIndex].value = (currentRouteQuery[queryKey] as string)
            .split(',')
            .map((number) => Number(number))
          break
        case FilterDataTypes.ArrayString:
          FILTER_FIELDS[filterFieldIndex].value = (currentRouteQuery[queryKey] as string).split(',')
          break
        case FilterDataTypes.Float:
          FILTER_FIELDS[filterFieldIndex].value = (currentRouteQuery[queryKey] as string).replace('.', ',')
          break
        case FilterDataTypes.Integer:
          FILTER_FIELDS[filterFieldIndex].value = Number(currentRouteQuery[queryKey])
          break
        case FilterDataTypes.PercentageNumber: {
          const value = Number(currentRouteQuery[queryKey]) * 100
          FILTER_FIELDS[filterFieldIndex].value = value === 0 ? null : value
          break
        }
        case FilterDataTypes.String:
          FILTER_FIELDS[filterFieldIndex].value = currentRouteQuery[queryKey]
          break
        case FilterDataTypes.Date:
          FILTER_FIELDS[filterFieldIndex].value = (currentRouteQuery[queryKey] as string).split('-').reverse().join('.')
          break
        case FilterDataTypes.BooleanDate:
          FILTER_FIELDS[filterFieldIndex].value = currentRouteQuery[queryKey]
          break
        case FilterDataTypes.Boolean:
          FILTER_FIELDS[filterFieldIndex].value = currentRouteQuery[queryKey] === 'true' ? true : false
          break
      }
    })
  }
}

function createFilterFieldsObject(filterFields: FilterField[]): OptionalFiltersParam {
  const filterFieldsCopy: FilterField[] = cloneDeep(filterFields)

  let mergedFilterFields: OptionalFiltersParam = {} as OptionalFiltersParam

  filterFieldsCopy.forEach((filterField: FilterField) => {
    switch (filterField.dataTyp) {
      case FilterDataTypes.ArrayNumber:
        filterField.value = Array.isArray(filterField.value) ? filterField.value?.toString() : filterField.value
        break
      case FilterDataTypes.ArrayString:
        filterField.value = Array.isArray(filterField.value) ? filterField.value?.join(',') : filterField.value
        break
      case FilterDataTypes.Date:
        filterField.value = isOfTypeString(filterField.value)
          ? dateDotNotationToDash(filterField.value)
          : filterField.value
        break
      case FilterDataTypes.Float:
        filterField.value = filterField.value = isOfTypeString(filterField.value)
          ? filterField.value?.replace(',', '.')
          : filterField.value
        break
      case FilterDataTypes.PercentageNumber: {
        const value = Number(filterField.value) / 100

        if (isNaN(value)) {
          filterField.value = null
        }

        break
      }
    }

    mergedFilterFields = { ...mergedFilterFields, ...{ [filterField.key]: filterField.value } }
  })

  return mergedFilterFields
}

export { useFilter }
