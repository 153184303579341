
import { computed, defineComponent, reactive, ref, watch } from '@vue/composition-api'
import { mdiAccountSearch, mdiPlus, mdiExportVariant, mdiFilter, mdiChevronDown, mdiChevronUp } from '@mdi/js'
import { isEqual } from 'lodash-es'

import CommonTableView from '@/components/common/CommonTableView.vue'
import CommonExportMenu from '@/components/common/CommonExportMenu.vue'

import { useTaskDialog } from '@/composables/useTaskDialog'
import { useNoteDialog } from '@/composables/useNoteDialog'
import { useFilter } from '@/composables/useFilter'

import { useDeletePerson, useGetPersons, useExportPersons, useGetPersonsBasic } from '@/api/person'
import { useGetOrgUnitsBasic } from '@/api/orgUnit'
import { useGetProfUnitsBasic } from '@/api/profUnit'
import { useGetProfilesBasic } from '@/api/profile'
import { useGetSuppliersBasic } from '@/api/supplier'

import { dateDotNotationToDash } from '@/utils/convertDate'
import { handleError } from '@/utils/handleError'
import { setSort } from '@/utils/manipulateTableSort'
import { hasSufficientRights } from '@/utils/hasSufficientRights'
import { convertDotIntoCommaInput } from '@/utils/convertInput'

import { FilterDataTypes, FilterField, OptionalFiltersParam } from '@/composables/types/useFilter'
import { FILTER_FIELD_KEY, positionFilter } from '@/views/persons/views/personsList/types'
import { PersonOutput } from '@/api/types/person'
import { Rights } from '@/api/types/right'
import { useNotify } from '@/store'
import { PROFILE_TYPE } from '@/api/types/profile'
import { CONTRACTUAL_RELATIONSHIP } from '@/views/types'
import { useGetEnumItemsBasic } from '@/api/enumItem'

export default defineComponent({
  name: 'Persons',
  components: {
    CommonTableView,
    CommonExportMenu,
    CommonDeleteDialog: () => import('@/components/common/CommonDeleteDialog.vue'),
    AddEditPersonDialog: () => import('@/views/persons/views/components/AddEditPersonDialog.vue'),
    CommonTasksDialog: () => import('@/components/common/CommonTasksDialog.vue'),
    CommonNotesDialog: () => import('@/components/common/CommonNotesDialog.vue'),
    PersonFilterBar: () => import('@/views/persons/views/personsList/components/PersonFilterBar.vue'),
  },
  setup: (_, { root }) => {
    const { addNotification } = useNotify()

    const TABLE_HEADERS = [
      {
        text: root.$t('persons.persons.table.col.title.id'),
        value: 'id',
        sortable: true,
        width: 120,
      },
      {
        text: root.$t('persons.persons.table.col.title.firstName'),
        value: 'firstName',
        sortable: true,
      },
      {
        text: root.$t('persons.persons.table.col.title.lastName'),
        value: 'lastName',
        sortable: true,
        width: 150,
      },
      {
        text: root.$t('persons.persons.table.col.title.contractRelationship'),
        value: 'contractRelationships',
        sortable: false,
      },
      {
        text: root.$t('persons.persons.table.col.title.organizationalUnit'),
        value: 'organizationalUnit.name',
        sortable: false,
      },
      {
        text: root.$t('persons.persons.table.col.title.professionalUnit'),
        value: 'professionalUnits',
        sortable: false,
      },
      {
        text: root.$t('persons.persons.table.col.title.profile'),
        value: 'profiles',
        sortable: false,
      },
      {
        text: root.$t('persons.persons.table.col.title.specialRoles'),
        value: 'specialRoles',
        sortable: false,
      },
      {
        text: root.$t('persons.persons.table.col.title.scope'),
        value: 'personScope',
        sortable: false,
      },
      {
        text: root.$t('persons.persons.table.col.title.supplier'),
        value: 'supplier.name',
        sortable: false,
      },
      {
        text: root.$t('persons.persons.table.col.title.tasksCount'),
        value: 'tasksCount',
        sortable: false,
      },
      {
        text: root.$t('persons.persons.table.col.title.notesCount'),
        value: 'notesCount',
        sortable: false,
      },
      {
        text: root.$t('persons.persons.table.col.title.active'),
        value: 'active',
        align: 'center',
        sortable: false,
      },
      {
        text: root.$t('persons.persons.table.col.title.actions'),
        value: 'actions',
        align: 'right',
        sortable: false,
      },
    ]

    const tableSearchTerm = ref('')

    const activePerson = ref<PersonOutput | null>(null)

    // add person
    const isAddPersonModalOpen = ref(false)

    // delete person
    const isDeletePersonDialogOpen = ref(false)

    function onClickDelete(person: PersonOutput): void {
      activePerson.value = { ...person }

      isDeletePersonDialogOpen.value = true
    }

    const { deletePerson } = useDeletePerson()

    async function onDeletePerson(): Promise<void> {
      try {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        await deletePerson(activePerson.value!.id)
      } catch (error: unknown) {
        handleError(error)
      }

      isDeletePersonDialogOpen.value = false

      activePerson.value = null

      debouncedCb.value()
    }

    function onCloseAddDeleteDialog(): void {
      isAddPersonModalOpen.value = false

      isDeletePersonDialogOpen.value = false

      activePerson.value = null
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function onDblClickRow([_, { item: person }]: [void, { item: PersonOutput }]): void {
      root.$router.push({
        name: 'person-profile',
        params: { id: String(person.id) },
      })
    }

    const isFilterDropdownOpen = ref(false)

    function onToggleFilterDropdown() {
      if (!isFilterDropdownOpen.value) {
        isFilterDropdownOpen.value = true

        if (
          !profUnits.value?.length &&
          !orgUnits.value?.length &&
          !profiles.value?.length &&
          !suppliers.value?.length &&
          !personsBasic.value?.length &&
          !enumItemsBasic.value?.length
        ) {
          getProfUnitsBasic()
          getOrgUnitsBasic()
          getProfilesBasic({ params: { type: PROFILE_TYPE.ASSIGNMENT } })
          getSuppliersBasic()
          getPersonsBasic()
          getEnumItemsBasic({ params: { enumItemAssignment: 'PERSON_SPECIAL_ROLE' } })
        }
      } else {
        isFilterDropdownOpen.value = false
      }
    }

    const { exec: getProfUnitsBasic, data: profUnits, isLoading: isLoadingProfUnits } = useGetProfUnitsBasic()

    const { exec: getOrgUnitsBasic, data: orgUnits, isLoading: isLoadingOrgUnits } = useGetOrgUnitsBasic()

    const { exec: getProfilesBasic, data: profiles, isLoading: isLoadingProfiles } = useGetProfilesBasic()

    const { exec: getSuppliersBasic, data: suppliers, isLoading: isLoadingSuppliers } = useGetSuppliersBasic()

    const { exec: getPersonsBasic, data: personsBasic, isLoading: isLoadingPersonsBasic } = useGetPersonsBasic()

    const {
      exec: getEnumItemsBasic,
      data: enumItemsBasic,
      isLoading: isLoadingEnumItemsBasic,
    } = useGetEnumItemsBasic()

    const FILTER_FIELDS = ref<FilterField[]>([
      {
        key: FILTER_FIELD_KEY.PersonName,
        value: null,
        label: root.$t('persons.filters.name'),
        items: [],
        dataTyp: FilterDataTypes.String,
      },
      {
        key: FILTER_FIELD_KEY.PersonIds,
        value: null,
        label: root.$t('persons.filters.personIds'),
        items: computed(() => personsBasic.value ?? []),
        isLoading: computed(() => isLoadingPersonsBasic.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.ProfessionalUnitIds,
        value: null,
        label: root.$t('persons.filters.profUnit'),
        items: computed(() => profUnits.value ?? []),
        isLoading: computed(() => isLoadingProfUnits.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.ProfessionalUnitIdsWithChildren,
        value: null,
        label: root.$t('persons.filters.profUnit'),
        items: computed(() => profUnits.value ?? []),
        isLoading: computed(() => isLoadingProfUnits.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.OrganizationalUnitIds,
        value: null,
        label: root.$t('persons.filters.orgUnit'),
        items: computed(() => orgUnits.value ?? []),
        isLoading: computed(() => isLoadingOrgUnits.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.OrganizationalUnitIdsWithChildren,
        value: null,
        label: root.$t('persons.filters.orgUnit'),
        items: computed(() => orgUnits.value ?? []),
        isLoading: computed(() => isLoadingOrgUnits.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.ProfileIds,
        value: null,
        label: root.$t('persons.filters.assignmentProfile'),
        items: computed(() => profiles.value ?? []),
        isLoading: computed(() => isLoadingProfiles.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.SpecialRoleIds,
        value: null,
        label: root.$t('persons.filters.specialRoles'),
        items: computed(() => enumItemsBasic.value ?? []),
        isLoading: computed(() => isLoadingEnumItemsBasic.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.SupplierIds,
        value: null,
        label: root.$t('persons.filters.supplier'),
        items: computed(() => suppliers.value ?? []),
        isLoading: computed(() => isLoadingSuppliers.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.ContractEndFrom,
        value: null,
        label: root.$t('persons.filters.contractEndFrom'),
        items: [],
        dataTyp: FilterDataTypes.Date,
      },
      {
        key: FILTER_FIELD_KEY.ContractStartTo,
        value: null,
        label: root.$t('persons.filters.contractStartTo'),
        items: [],
        dataTyp: FilterDataTypes.Date,
      },
      {
        key: FILTER_FIELD_KEY.ContractualRelationships,
        value: null,
        label: root.$t('persons.filters.contractualRelationship'),
        items: computed(() => CONTRACTUAL_RELATIONSHIP),
        dataTyp: FilterDataTypes.ArrayString,
      },
      {
        key: FILTER_FIELD_KEY.IncludeAnonymized,
        value: null,
        label: root.$t('persons.filters.includeAnonymized'),
        items: [],
        dataTyp: FilterDataTypes.Boolean,
      },
      {
        key: FILTER_FIELD_KEY.Active,
        value: null,
        label: root.$t('persons.filters.active'),
        items: [],
        dataTyp: FilterDataTypes.BooleanDate,
      },
      {
        key: FILTER_FIELD_KEY.Position,
        value: null,
        label: root.$t('persons.filters.position'),
        items: computed(() => positionFilter ?? []),
        dataTyp: FilterDataTypes.Boolean,
      },
      {
        key: FILTER_FIELD_KEY.ActivePosition,
        value: null,
        label: root.$t('persons.filters.activePosition'),
        items: computed(() => positionFilter ?? []),
        dataTyp: FilterDataTypes.Date,
      },
      {
        key: FILTER_FIELD_KEY.NotActivePosition,
        value: null,
        label: root.$t('persons.filters.notActivePosition'),
        items: computed(() => positionFilter ?? []),
        dataTyp: FilterDataTypes.Date,
      },
    ])

    function getTodaysDate() {
      const today = new Date()
      const year = today.getFullYear()
      const month = (today.getMonth() + 1).toString().padStart(2, '0') // Month is 0-indexed, add 1
      const day = today.getDate().toString().padStart(2, '0')
      return `${year}-${month}-${day}`
    }

    function getFilterByKey(key: FILTER_FIELD_KEY): FilterField {
      return FILTER_FIELDS.value.find((field) => field.key === key) as FilterField
    }

    watch(
      () => FILTER_FIELDS.value,
      () => {
        const withPositions = getFilterByKey(FILTER_FIELD_KEY.Position).value
        if (withPositions) {
          getFilterByKey(FILTER_FIELD_KEY.ActivePosition).value = getTodaysDate()
          getFilterByKey(FILTER_FIELD_KEY.NotActivePosition).value = null
        } else if (withPositions === false) {
          getFilterByKey(FILTER_FIELD_KEY.ActivePosition).value = null
          getFilterByKey(FILTER_FIELD_KEY.NotActivePosition).value = getTodaysDate()
        } else {
          getFilterByKey(FILTER_FIELD_KEY.NotActivePosition).value = null
          getFilterByKey(FILTER_FIELD_KEY.ActivePosition).value = null
        }
      },
      { deep: true }
    )

    const { debouncedCb, vuetifyTableOptions, paginationParams, filterFieldsObject, isInit } = useFilter(
      FILTER_FIELDS.value as FilterField[],
      init
    )
    watch(
      filterFieldsObject,
      (newVal, oldVal) => {
        isInit.value = false

        if (!isEqual(newVal, oldVal)) {
          debouncedCb.value()
          vuetifyTableOptions.value.page = 1
        }
      },
      { deep: true }
    )

    setSort(vuetifyTableOptions, { by: 'lastName', desc: false })

    const { exec: getPersons, data: persons, isLoading: isLoadingPersons, paginationResponse } = useGetPersons()

    const totalPersons = computed(() => paginationResponse.value.totalElements)

    async function init(filterFieldsObject?: OptionalFiltersParam): Promise<void> {
      await getPersons({
        params: { ...paginationParams.value, ...filterFieldsObject, includeAnonymized: false },
      })
    }

    const { exportPersons, isLoading: isLoadingExport } = useExportPersons()

    async function onExportPersons(dateRange: { startMonth: string; endMonth: string }): Promise<void> {
      try {
        await exportPersons({
          ...filterFieldsObject.value,
          startMonth: dateDotNotationToDash(dateRange.startMonth),
          endMonth: dateDotNotationToDash(dateRange.endMonth),
          includeAnonymized: false,
        })

        addNotification({
          text: root.$t('misc.export.actions.success') as string,
          timeout: 5000,
          type: 'success',
        })
      } catch (error: unknown) {
        handleError(error)
      }
    }

    const {
      isOpen: isNotesDialogOpen,
      notes: personNotes,
      onClickOpenDialog: onOpenNotesDialog,
      onReload: onReloadNotes,
      entityId: notesEntityId,
    } = useNoteDialog<PersonOutput>(persons, () => init(filterFieldsObject.value))

    const {
      isOpen: isTasksDialogOpen,
      tasks: personTasks,
      onClickOpenDialog: onOpenTasksDialog,
      onReload: onReloadTasks,
      entityId: tasksEntityId,
    } = useTaskDialog<PersonOutput>(persons, () => init(filterFieldsObject.value))

    return reactive({
      icons: {
        mdiAccountSearch,
        mdiPlus,
        mdiExportVariant,
        mdiFilter,
        mdiChevronDown,
        mdiChevronUp,
      },
      constants: {
        Rights,

        TABLE_HEADERS,

        FILTER_FIELDS,
      },
      state: {
        tableSearchTerm,

        isAddPersonModalOpen,

        activePerson,

        isTasksDialogOpen,
        personTasks,
        tasksEntityId,

        isNotesDialogOpen,
        personNotes,
        notesEntityId,

        persons,
        isLoadingPersons,
        totalPersons,
        vuetifyTableOptions,

        isDeletePersonDialogOpen,

        isFilterDropdownOpen,

        isLoadingExport,
      },
      listeners: {
        onDblClickRow,

        onClickDelete,
        onDeletePerson,

        onOpenTasksDialog,
        onReloadTasks,

        onOpenNotesDialog,
        onReloadNotes,

        onCloseAddDeleteDialog,

        onToggleFilterDropdown,

        onExportPersons,
      },
      functions: {
        debouncedCb,

        convertDotIntoCommaInput,

        hasSufficientRights,
      },
    })
  },
})
