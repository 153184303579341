import { i18n } from '@/plugins/i18n'

enum FILTER_FIELD_KEY {
  PersonIds = 'ids',
  PersonName = 'name',
  Active = 'active',
  ContractualRelationships = 'contractualRelationships',
  ProfessionalUnitIds = 'professionalUnitIds',
  ProfessionalUnitIdsWithChildren = 'professionalUnitIdsWithChildren',
  OrganizationalUnitIds = 'organizationalUnitIds',
  OrganizationalUnitIdsWithChildren = 'organizationalUnitIdsWithChildren',
  ProfileIds = 'profileIds',
  SupplierIds = 'supplierIds',
  ContractStartFrom = 'contractStartFrom',
  ContractStartTo = 'contractStartTo',
  ContractEndFrom = 'contractEndFrom',
  ContractEndTo = 'contractEndTo',
  IncludeAnonymized = 'includeAnonymized',
  Position = 'showPositions',
  ActivePosition = 'activePosition',
  NotActivePosition = 'notActivePosition',
  SpecialRoleIds = 'specialRoleIds',
}

const positionFilter = [
  { name: i18n.t('persons.filters.allPositions'), value: null },
  { name: i18n.t('persons.filters.activePosition'), value: true },
  { name: i18n.t('persons.filters.notActivePosition'), value: false },
]

export { FILTER_FIELD_KEY, positionFilter }
