import { ref } from '@vue/composition-api'

import { BasicNoteOutput } from '@/api/types/note'

import { Entity, EntityRef, UseNoteDialogReturnType } from '@/composables/types/useNoteDialog'
import { OptionalFiltersParam } from '@/composables/types/useFilter'

function useNoteDialog<T>(
  parentEntity: EntityRef<T>,
  reloadCallback: (filterFieldsObject?: OptionalFiltersParam) => Promise<void>
): UseNoteDialogReturnType<T> {
  const isOpen = ref(false)

  const entityId = ref<number | null>(null)

  const notes = ref<BasicNoteOutput[]>([])

  function assignNotes(newNotes: BasicNoteOutput[]) {
    notes.value = [...newNotes]
  }

  function onClickOpenDialog(entity: Entity<T>) {
    isOpen.value = true

    entityId.value = entity.id

    assignNotes(entity.notes)
  }

  async function onReload(): Promise<void> {
    let newNotes: BasicNoteOutput[] = []

    await reloadCallback()

    newNotes = parentEntity.value.find((entity) => entity.id === entityId.value)?.notes ?? []

    assignNotes(newNotes)
  }

  return {
    isOpen,
    notes,
    onClickOpenDialog,
    onReload,
    entityId,
  }
}

export { useNoteDialog }
