import { useAxios } from '@/composables/useAxios'
import { useAxiosPaginated } from '@/composables/useAxiosPaginated'

import {
  SupplierInput,
  UseCreateSupplier,
  UseGetSuppliers,
  UseGetSuppliersBasic,
  UseUpdateSupplier,
  UseDeleteSupplier,
  UseExportSuppliers,
  SupplierOutput,
  SupplierId,
} from '@/api/types/supplier'
import { BasicEntity } from '@/api/types/misc'
import { PromiseType } from '@/utils/types/PromiseType'
import { OptionalFiltersParam } from '@/composables/types/useFilter'

const VERSION = 'v1'
const RESOURCE = 'suppliers'

function useGetSuppliers(): UseGetSuppliers {
  const axios = useAxiosPaginated<PromiseType<ReturnType<UseGetSuppliers['exec']>>>({
    method: 'GET',
    url: `/${VERSION}/${RESOURCE}`,
    params: { size: 9999 },
  })
  return axios
}

function useGetSuppliersBasic(): UseGetSuppliersBasic {
  const axios = useAxios<BasicEntity[]>({ method: 'GET', url: `/${VERSION}/${RESOURCE}/basic` })
  return axios
}

function useCreateSupplier(): UseCreateSupplier {
  const axios = useAxios<PromiseType<ReturnType<UseCreateSupplier['createSupplier']>>>({
    method: 'POST',
    url: `/${VERSION}/${RESOURCE}`,
  })

  function createSupplier(data: SupplierInput): Promise<SupplierOutput> {
    return axios.exec({ data })
  }

  return {
    ...axios,
    createSupplier,
  }
}

function useUpdateSupplier(): UseUpdateSupplier {
  const axios = useAxios<PromiseType<ReturnType<UseUpdateSupplier['updateSupplier']>>>({ method: 'PUT' })

  function updateSupplier(id: SupplierId, data: SupplierOutput): Promise<SupplierOutput> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}`, data })
  }

  return {
    ...axios,
    updateSupplier,
  }
}

function useDeleteSupplier(): UseDeleteSupplier {
  const axios = useAxios<PromiseType<ReturnType<UseDeleteSupplier['deleteSupplier']>>>({ method: 'DELETE' })

  function deleteSupplier(id: SupplierId): Promise<void> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}` })
  }

  return {
    ...axios,
    deleteSupplier,
  }
}

function useExportSuppliers(): UseExportSuppliers {
  const axios = useAxios<void>({ method: 'GET' })

  async function exportSuppliers(filters?: OptionalFiltersParam): Promise<void> {
    await axios.exec({ url: `/${VERSION}/${RESOURCE}/export`, params: filters })
  }

  return {
    ...axios,
    exportSuppliers,
  }
}

export {
  useGetSuppliers,
  useGetSuppliersBasic,
  useCreateSupplier,
  useUpdateSupplier,
  useDeleteSupplier,
  useExportSuppliers,
}
